import * as React from 'react';
import {ContentModal} from '../ui/modals/ContentModal/ContentModal';
import Button from '../ui/Buttons/Button';

interface PromoNotMetModalProps {
    campaignId: string;
    hasLandingPage: boolean;
    onClose: () => void;
    show: boolean;
}

export const PromoNotMetModal = ({campaignId, hasLandingPage, onClose, show}: PromoNotMetModalProps) => (
    <ContentModal
        footer={
            <div className="tw-text-right">
                {hasLandingPage && (
                    <Button
                        modal={true}
                        modalOrderChangeButton1={true}
                        onClick={() => location.assign(`/deals/${campaignId}`)}
                        size="smToLg"
                        variant="outline-secondary"
                    >
                        Learn More
                    </Button>
                )}
                <Button
                    className="lg:!tw-mb-1"
                    modal={true}
                    modalOrderChangeButton2={true}
                    onClick={onClose}
                    size="smToLg"
                    variant="secondary"
                >
                    Continue Shopping
                </Button>
            </div>
        }
        onClose={onClose}
        show={show}
        title="Promotion Details"
    >
        <p>The minimum requirements have not yet been met for this promotion.</p>
    </ContentModal>
);
