import {Observable, Observer} from 'rxjs';

import {AddToOrderModal} from '../../shared/orders/add-to-order/AddToOrderModal';
import {ErrorModal} from '../../shared/error-handling/ErrorModal';
import {gaLegacyCustomEvent} from '../ga/ga-legacy.functions';
import {ImpError} from '../imp-error/imp-error.class';
import {ItemToAdd, MultipleItemAddRes} from '../../shared/order-items/order-items.class';
import {OrderItemsService} from './order-items.service';
import {OrderLine} from '../../shared/orders/order.types';
import {OrdersService} from '../orders/orders.service';
import {OrdersWorkflow} from '../orders/orders.workflow';
import {ProgressOverlay} from '../components/ProgressOverlay';
import {ReactClientService} from '../react/react-client.service';
import {SessionStorageService} from '../session-storage/session-storage.service';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {UsersService} from '../users/users.service';
import {UserStateService} from '../users/user-state.service';
import {User} from '../../shared/users/user.class';

export class OrderItemsWorkflow {
    private _currentUser: User;
    private readonly _reactClientService: ReactClientService;

    constructor(
        private _orderItemsService: OrderItemsService,
        private _ordersService: OrdersService,
        private _ordersWorkflow: OrdersWorkflow,
        private _sessionStorageService: SessionStorageService,
        private _usersService: UsersService,
        private _userStateService: UserStateService,
    ) {
        this._currentUser = this._usersService.getCurrentUser();
        this._reactClientService = new ReactClientService();
    }

    /**
     * Performs addToOrderWorkflow without integrated success/error UI
     * @param itemsToAdd - Array of items to add
     * @param componentName - Component performing the addToOrder
     * @param increment - Increment (update) or replace (add) existing order lines
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     */
    public addToOrder(
        itemsToAdd: ItemToAdd[],
        componentName: string,
        increment: boolean,
        onPopUpCallback: () => void,
    ): Promise<MultipleItemAddRes> {
        return new Promise((resolve, reject) => {
            this._addToOrderWorkflow(itemsToAdd, componentName, increment, onPopUpCallback).subscribe({
                next: (addToOrderRes) => {
                    resolve(addToOrderRes);
                },
                error: (addToOrderErr: ImpError) => {
                    reject(addToOrderErr);
                },
            });
        });
    }

    /**
     * Adds items and shows results via PopUp
     * @param itemsToAdd - Array of items to add
     * @param componentName - Component performing the addToOrder
     * @param increment - Increment (update) or replace (add) existing order lines
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     * @param returnURL - Url to return to following login or account selection
     * @param observer - Handler for success and failure
     * @param ignoreCurrentOrder - If current order should be ignored (triggering open order picker)
     */
    public addToOrderModal(
        itemsToAdd: ItemToAdd[],
        componentName: string,
        increment: boolean,
        onPopUpCallback?: () => void,
        returnURL?: string,
        observer?: Observer<MultipleItemAddRes>,
        ignoreCurrentOrder?: boolean,
    ) {
        ProgressOverlay.activate();
        this._addToOrderWorkflow(itemsToAdd, componentName, increment, onPopUpCallback, returnURL, ignoreCurrentOrder).subscribe({
            next: (addToOrderRes) => {
                ProgressOverlay.deactivate();

                // User cancelled transaction, complete or return
                if (!addToOrderRes) {
                    if (observer) {
                        observer.next(null);
                        observer.complete();
                    } else {
                        return;
                    }
                } else {
                    // Get items for GA
                    const itemErrorsArray = [];
                    const itemSuccessesArray = [];
                    addToOrderRes.items.forEach((items) => {
                        if (items.error) {
                            itemErrorsArray.push(items.item);
                        } else {
                            itemSuccessesArray.push(items.item);
                        }
                    });
                    const itemErrors = itemErrorsArray.join(`|`);

                    // Display AddToOrderComponent on success
                    this._ordersService
                        .getCurrentOrder()
                        .then((getCurrentOrderRes) => {
                            // Call onPopUpCallback if exists (close calling UI)
                            if (onPopUpCallback) {
                                onPopUpCallback();
                            }

                            // Render AddToOrderComponent
                            const [root] = this._reactClientService.appendComponent(
                                AddToOrderModal,
                                {
                                    addedItems: addToOrderRes.items,
                                    onClose: () => root.unmount(),
                                    order: getCurrentOrderRes,
                                    show: true,
                                    user: this._currentUser,
                                },
                                $(`#reactModalElement`)[0],
                            );

                            // Return response if using an observable
                            if (observer) {
                                observer.next(addToOrderRes);
                                observer.complete();
                            } else {
                                return;
                            }
                        })
                        .catch((getCurrentOrderErr: ImpError) => {
                            OrderItemsWorkflow._sendErrorAnalytics(componentName, getCurrentOrderErr.message, itemErrors);
                            this._showErrorPopUp(getCurrentOrderErr.message, onPopUpCallback, observer);
                        });
                }
            },
            error: (addToOrderErr: ImpError) => {
                // Get items for GA
                const itemErrorsArray = [];
                itemsToAdd.forEach((items) => {
                    itemErrorsArray.push(items.item);
                });
                OrderItemsWorkflow._sendErrorAnalytics(
                    componentName,
                    addToOrderErr.message,
                    itemErrorsArray ? itemErrorsArray.join(`|`) : null,
                );
                this._showErrorPopUp(addToOrderErr.message, onPopUpCallback, observer);
            },
        });
    }

    /**
     * Adds items and shows results via PopUp, using an observable
     * @param itemsToAdd - Array of items to add
     * @param componentName - Component performing the addToOrder
     * @param increment - Increment (update) or replace (add) existing order lines
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     * @param returnURL - Url to return to following login or account selection
     * @param ignoreCurrentOrder - If current order should be ignored (triggering open order picker)
     */
    public addToOrderModalObservable(
        itemsToAdd: ItemToAdd[],
        componentName: string,
        increment: boolean,
        onPopUpCallback?: () => void,
        returnURL?: string,
        ignoreCurrentOrder?: boolean,
    ): Observable<MultipleItemAddRes> {
        return new Observable((observer: Observer<MultipleItemAddRes>) => {
            this.addToOrderModal(itemsToAdd, componentName, increment, onPopUpCallback, returnURL, observer, ignoreCurrentOrder);
        });
    }

    /**
     * Adds items to order and navigates to currentOrder
     * @param itemsToAdd
     * @param componentName
     * @param increment
     */
    public addToOrderNavOrder(itemsToAdd: ItemToAdd[], componentName: string, increment: boolean) {
        ProgressOverlay.activate();
        this._addToOrderWorkflow(itemsToAdd, componentName, increment, null, null).subscribe({
            next: (addToOrderNavOrderRes) => {
                // User cancelled transaction, complete
                if (!addToOrderNavOrderRes) {
                    ProgressOverlay.deactivate();
                    return;
                }

                // Save errored items to session for display after redirect
                const addToOrderErroredItems = [];
                for (const item of addToOrderNavOrderRes.items) {
                    if (item.error) {
                        gaLegacyCustomEvent({
                            eventAction: `Invalid Items`,
                            eventCategory: `${componentName}` as any,
                            eventLabel: item.item,
                        });
                        addToOrderErroredItems.push(item);
                    }
                }
                if (addToOrderErroredItems.length) {
                    this._sessionStorageService.setItem(`addToOrderErroredItems`, JSON.stringify(addToOrderErroredItems));
                }

                // Navigate to current order
                location.assign(`/orders/${this._ordersService.currentOrderNumber}`);
            },
            error: (addToOrderNavOrderErr: ImpError) => {
                OrderItemsWorkflow._sendErrorAnalytics(componentName, addToOrderNavOrderErr.message);
                this._showErrorPopUp(addToOrderNavOrderErr.message);
            },
        });
    }

    /**
     * Adds an item to the order and shows result via toast
     * @param itemsToAdd - Array of items to add
     * @param componentName - Component performing the addToOrder
     * @param increment - Increment (update) or replace (add) existing order lines
     * @param message - Message to display in the toast
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     */
    public addToOrderToast(
        itemsToAdd: ItemToAdd[],
        componentName: string,
        increment: boolean,
        message?: string,
        onPopUpCallback?: () => void,
    ): Observable<MultipleItemAddRes> {
        return new Observable((observer: Observer<MultipleItemAddRes>) => {
            this._addToOrderWorkflow(itemsToAdd, componentName, increment, onPopUpCallback).subscribe({
                next: (addToOrderRes) => {
                    // User cancelled transaction, complete
                    if (!addToOrderRes) {
                        ProgressOverlay.deactivate();
                        observer.next(null);
                        observer.complete();

                        // Display SnackbarComponent on success
                    } else {
                        if (message) {
                            new SnackbarComponent(message);
                        } else {
                            const multipleItems = itemsToAdd[0].unitsOrdered > 1 || itemsToAdd[0].qtyOrd > 1 || itemsToAdd.length > 1;
                            new SnackbarComponent(`Item${multipleItems ? `s` : ``} Added to Order`);
                        }
                        observer.next(addToOrderRes);
                        observer.complete();
                    }
                },
                error: (addToOrderErr: ImpError) => {
                    OrderItemsWorkflow._sendErrorAnalytics(componentName, addToOrderErr.message);
                    observer.error(addToOrderErr);
                    observer.complete();
                },
            });
        });
    }

    /**
     * Drives jQuery UI for replacing orderLine with itemToAdd
     * @param orderLineToRemove - Order line to replace
     * @param itemToAdd - Item to add to order
     * @param componentName - Component performing replacement
     * @param afterReplaceAction
     */
    public replaceOrderLine(orderLineToRemove: OrderLine, itemToAdd: ItemToAdd, componentName: string, afterReplaceAction?: () => void) {
        ProgressOverlay.activate();
        this._orderItemsService
            .replaceOrderLine(orderLineToRemove, itemToAdd, componentName)
            .then(() => {
                ProgressOverlay.deactivate();
                new SnackbarComponent(`Order Updated`);
                if (afterReplaceAction) {
                    afterReplaceAction();
                }
            })
            .catch((replaceOrderLineErr: ImpError) => {
                ProgressOverlay.deactivate();
                const [root] = this._reactClientService.appendComponent(
                    ErrorModal,
                    {errorMsg: replaceOrderLineErr.message, onClose: () => root.unmount(), show: true},
                    $(`#reactModalElement`)[0],
                );
            });
    }

    /**
     * Adds items to a new order or an existing order
     * @param itemsToAdd - Array of items to add
     * @param componentName - Component performing the addToOrder
     * @param increment - Increment (update) or replace (add) existing order lines
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     * @param returnURL - Url to return to following login or account selection
     * @param ignoreCurrentOrder - If current order should be ignored (triggering open order picker)
     */
    private _addToOrderWorkflow(
        itemsToAdd: ItemToAdd[],
        componentName: string,
        increment: boolean,
        onPopUpCallback?: () => void,
        returnURL?: string,
        ignoreCurrentOrder?: boolean,
    ): Observable<MultipleItemAddRes> {
        return new Observable((observer: Observer<MultipleItemAddRes>) => {
            // Validate itemsToAdd
            const validatedItemsToAdd = OrderItemsService.validateItemsToAdd(itemsToAdd);

            // Record addToOrder action
            this._userStateService.recordAddToOrderAction(componentName, validatedItemsToAdd, increment ? `update` : `add`);

            // Reject if there are no valid items to add
            if (!this._userStateService.addToOrderItems.length) {
                this._userStateService.clearPendingAction();
                observer.error(new Error(`No Valid Items To Add`));
                observer.complete();
                return;
            }

            // If not logged in, showLoginPopUp
            if (!this._currentUser.isLoggedIn()) {
                this._usersService.showLoginPopUp(onPopUpCallback, returnURL);
                observer.next(null);
                observer.complete();
                return;
            }

            // Determine if item can be added to currentOrder
            if (!ignoreCurrentOrder && this._ordersService.currentOrderNumber) {
                this._ordersService
                    .getCurrentOrder()
                    .then(() => {
                        this._orderItemsService
                            .multipleItemAdd()
                            .then((multipleItemAddRes) => {
                                observer.next(multipleItemAddRes);
                                observer.complete();
                            })
                            .catch((multipleItemAddErr: ImpError) => {
                                observer.error(multipleItemAddErr);
                                observer.complete();
                            });
                    })
                    .catch((getCurrentOrderErr: ImpError) => {
                        observer.error(getCurrentOrderErr);
                        observer.complete();
                    });

                // Else continue workflow
            } else {
                // If not activeAccount, present AccountPickerComponent in select mode
                if (!this._currentUser.activeAccount) {
                    this._usersService.showAccountPicker(true, onPopUpCallback, returnURL);
                    observer.next(null);
                    observer.complete();
                    return;
                }

                // If createNewOrderSelected, createOrderAddItems
                if (this._userStateService.createNewOrderSelected) {
                    this._createOrderAddItems(observer, onPopUpCallback);

                    // Else show OpenOrdersComponent
                } else {
                    this._ordersWorkflow.showOpenOrderPicker(onPopUpCallback).subscribe({
                        next: (showOpenOrderPickerRes) => {
                            if (showOpenOrderPickerRes === `createNewOrder`) {
                                this._createOrderAddItems(observer, onPopUpCallback);
                            } else if (showOpenOrderPickerRes) {
                                this._orderItemsService.loadOrderAddItems(showOpenOrderPickerRes, observer);
                            } else {
                                observer.next(null);
                                observer.complete();
                            }
                        },
                        error: (showOpenOrderPickerErr: ImpError) => {
                            observer.error(showOpenOrderPickerErr);
                            observer.complete();
                        },
                    });
                }
            }
        });
    }

    /**
     * Creates a new order and _loadOrderAddItems
     * @param observer - Used to communicate response
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     * @private
     */
    private _createOrderAddItems(observer: Observer<MultipleItemAddRes>, onPopUpCallback: () => void) {
        this._ordersWorkflow.createOrder(null, onPopUpCallback).subscribe({
            next: (createOrderRes) => {
                if (createOrderRes && createOrderRes.ordrNbr) {
                    this._orderItemsService.loadOrderAddItems(createOrderRes.ordrNbr, observer);
                } else {
                    observer.error(new Error(`There was a problem adding your item(s)`));
                    observer.complete();
                }
            },
            error: (createOrderErr: ImpError) => {
                observer.error(createOrderErr);
                observer.complete();
            },
        });
    }

    /**
     * Sends error analytics
     * @param componentName - Workflow entry component name encountering the error
     * @param errorMessage - Analytics label to use
     * @param items
     * @private
     */
    private static _sendErrorAnalytics(componentName: string, errorMessage: string, items?: string) {
        gaLegacyCustomEvent({
            eventAction: `Add to Order Error`,
            eventCategory: `${componentName}` as any,
            eventLabel: items ? `${items} | ${errorMessage}` : errorMessage,
        });
    }

    /**
     * Displays provided errorMessage via ErrorPopupComponent
     * @param errorMessage - Error message to display
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     * @param observer - Handler for success and failure
     * @private
     */
    private _showErrorPopUp(errorMessage: string, onPopUpCallback?: () => void, observer?: Observer<MultipleItemAddRes>) {
        ProgressOverlay.deactivate();

        // Hide Calling UI
        if (onPopUpCallback) {
            onPopUpCallback();
        }

        // Show ErrorModal
        const [root] = this._reactClientService.appendComponent(
            ErrorModal,
            {errorMsg: errorMessage, onClose: () => root.unmount(), show: true},
            $(`#reactModalElement`)[0],
        );

        // Return error to caller
        if (observer) {
            observer.error(new Error(errorMessage));
            observer.complete();
        } else {
            return;
        }
    }
}
