import * as React from 'react';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Observer} from 'rxjs';
import {useEffect, useState} from 'react';

import {BlockHeader} from '../ui/BlockHeader/BlockHeader';
import {ContentModal} from '../ui/modals/ContentModal/ContentModal';
import {ImpError} from '../../client/imp-error/imp-error.class';
import {PromoCard} from './PromoCard';
import {PromosService} from '../../client/promos/promos.service';
import {PromoTierOption, UpdatePromoCodeRes} from './promo.class';
import {User} from '../users/user.class';
import {useService} from '../react/ServiceContext';
import Button from '../ui/Buttons/Button';

interface PromoItemPickerModalProps {
    applyPromoObserver?: Observer<UpdatePromoCodeRes>;
    componentName: string;
    onHide: () => void;
    pcode: string;
    promoTierOptions: PromoTierOption[];
    show: boolean;
    user: User;
}

export const PromoItemPickerModal = ({
    applyPromoObserver,
    componentName,
    onHide,
    pcode,
    promoTierOptions,
    show,
    user,
}: PromoItemPickerModalProps) => {
    const [qualifiesForChoice, setQualifiesForChoice] = useState(false);
    const [selectedPromoEmail, setSelectedPromoEmail] = useState(user.loginName);
    const [selectedPromoTier, setSelectedPromoTier] = useState(``);
    const [selection, setSelection] = useState(``);
    const promosService: PromosService = useService(`promosService`);

    /**
     * Reviews all promo options to determine if _qualifiesForChoice
     * @private
     */
    useEffect(() => {
        if (!promoTierOptions) {
            return;
        }
        for (const promoTierOption of promoTierOptions) {
            if (promoTierOption.qualify) {
                setQualifiesForChoice(true);
                break;
            } else {
                for (const promoItem of promoTierOption.items) {
                    if (promoItem.qualify) {
                        setQualifiesForChoice(true);
                        break;
                    }
                }
            }
        }
    }, [promoTierOptions]);

    if (!promoTierOptions) {
        return <></>;
    }
    /**
     * Applies promo code to order
     */
    const submitPromoItemPickerForm = () => {
        onHide();
        promosService.applyPromoCode(pcode, componentName, selectedPromoEmail, selection, selectedPromoTier).subscribe({
            next: (applyPromoCodeRes) => {
                if (applyPromoObserver) {
                    applyPromoObserver.next(applyPromoCodeRes);
                    applyPromoObserver.complete();
                }
            },
            error: (applyPromoCodeErr: ImpError) => {
                if (applyPromoObserver) {
                    applyPromoObserver.error(applyPromoCodeErr);
                    applyPromoObserver.complete();
                }
            },
        });
    };

    /**
     * Template
     */
    return (
        <>
            <ContentModal
                footer={
                    <Button
                        className="!tw-w-full lg:!tw-w-auto"
                        modal={true}
                        disabled={!selection}
                        onClick={() => {
                            submitPromoItemPickerForm();
                        }}
                        size="smToLg"
                        type="button"
                        variant="secondary"
                    >
                        Continue
                    </Button>
                }
                onClose={onHide}
                show={show}
                title={<>Select an Item</>}
            >
                <>
                    {!qualifiesForChoice && (
                        <div
                            className="tw-relative tw-py-3 tw-px-5 tw-mb-4 tw-border tw-border-[#ffe2c6] tw-rounded-sm tw-flex tw-item-center tw-text-[#85501b] tw-bg-[#ffebd6]"
                            role="alert"
                        >
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                            The minimum requirements have not yet been met for this promotion.
                        </div>
                    )}
                    <div>
                        {promoTierOptions?.map((promoTierOption, promoTierOptionIndex) => (
                            <React.Fragment key={promoTierOptionIndex}>
                                <BlockHeader>{promoTierOption.ruleDesc}</BlockHeader>
                                {promoTierOption.items.length <= 1 && promoTierOptions.length > 1 ? (
                                    <PromoCard
                                        key={promoTierOptionIndex}
                                        onSelect={(itemNum) => setSelection(itemNum)}
                                        onSelectEmail={(email) => setSelectedPromoEmail(email)}
                                        onSelectTier={(index) => setSelectedPromoTier(index)}
                                        promoItem={promoTierOption.items[0]}
                                        promoItemIndex={promoTierOptionIndex}
                                        promoTierOptionIndex={promoTierOptionIndex}
                                        selectedPromoEmail={selectedPromoEmail}
                                        selection={selection}
                                    />
                                ) : (
                                    <div
                                        className={`tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-${
                                            promoTierOption.items.length < 4 ? promoTierOption.items.length : 3
                                        }`}
                                    >
                                        {promoTierOption.items.map((promoItem, promoItemIndex) => (
                                            <PromoCard
                                                key={promoItemIndex}
                                                onSelect={(item) => setSelection(item)}
                                                onSelectEmail={(email) => setSelectedPromoEmail(email)}
                                                onSelectTier={(index) => setSelectedPromoTier(index)}
                                                promoItem={promoItem}
                                                promoItemIndex={promoItemIndex}
                                                promoTierOptionIndex={promoTierOptionIndex}
                                                selectedPromoEmail={selectedPromoEmail}
                                                selection={selection}
                                            />
                                        ))}
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </>
            </ContentModal>
        </>
    );
};
