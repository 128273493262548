import * as React from 'react';
import {useEffect, useRef, useState} from 'react';

import Button from '../../ui/Buttons/Button';
import {Account} from '../../users/user.types';
import {Alerts} from '../../ui/Alerts/Alerts';
import {ContentModal} from '../../ui/modals/ContentModal/ContentModal';
import {EmptyState} from '../../ui/EmptyState/EmptyState';
import {filterAllAccounts, filterVisible, getAccountFinderView} from './AccountFinderView';
import {FilterInput} from '../../ui/forms/FilterInput/FilterInput';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {Spinner} from '../../ui/Spinner/Spinner';
import {UsersService} from '../../../client/users/users.service';
import {UserStateService} from '../../../client/users/user-state.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

interface AccountFinderModalProps {
    accountOnlyMode?: boolean;
    onHide: () => void;
    returnUrl?: string;
    selectAccountMode: boolean;
    show: boolean;
    user: User;
}

export const AccountFinderModal = ({accountOnlyMode, onHide, returnUrl, selectAccountMode, show, user}: AccountFinderModalProps) => {
    const [accountKeys, setAccountKeys] = useState<string[]>([]);
    const [collapsed, setCollapsed] = useState(false);
    const [errorMessage, setErrorMessage] = useState(``);
    const [filter, setFilter] = useState(``);
    const accountsRef = useRef<Account[]>([]);
    const usersService: UsersService = useService(`usersService`);
    const userStateService: UserStateService = useService(`userStateService`);

    // This var is used to force a rerender
    const [, update] = useState<any>();
    const accounts = accountsRef.current;

    // Get accounts when displaying modal
    useEffect(() => {
        if (show === true) {
            usersService
                .getAccountList()
                .then((getAccountListRes) => {
                    const emptyDistrict: Account[] = [];
                    const accountsWithSiblings: Account[] = [];
                    const rootLevelAccounts: Account[] = [];

                    // Build rootLevelAccounts and accountsWithSiblings
                    getAccountListRes.forEach((account, i) => {
                        if (account.type === `Account`) {
                            rootLevelAccounts.push(getAccountListRes[i]);
                        } else {
                            accountsWithSiblings.push(getAccountListRes[i]);
                        }
                    });

                    // Create empty district if rootLevelAccounts
                    if (rootLevelAccounts.length) {
                        emptyDistrict.push({
                            account: `Unassigned Accounts`,
                            accounts: rootLevelAccounts,
                            attn: ``,
                            city: ``,
                            contact: ``,
                            custAcct: ``,
                            hideLogin: true,
                            name: ``,
                            st: ``,
                            type: `District`,
                            zip: ``,
                        });
                    }

                    // Assign and update
                    accountsRef.current = [...emptyDistrict, ...accountsWithSiblings];
                    update({});
                })
                .catch((getAccountListErr: ImpError) => {
                    setErrorMessage(getAccountListErr.message);
                });
        }
    }, [show]);

    useEffect(() => {
        filterAllAccounts(filter, accountsRef.current, () => {
            update({});
        });
    }, [filter]);

    // Handle optional returnUrl client-side
    if (typeof window !== `undefined`) {
        // Replace order detail URLs with /orders to avoid auto account switching
        const currentUrl = `${location.pathname}${location.search}`;
        if (returnUrl?.startsWith(`/orders/`) || currentUrl.startsWith(`/orders/`)) {
            returnUrl = `/orders`;
        }

        // Default to currentUrl if none provided
        returnUrl = returnUrl || currentUrl;
    }

    /**
     * Handles collapse all / expand all button
     */
    const collapseDivs = () => {
        const allAccounts = [];
        for (const accountLevel1 of accounts) {
            allAccounts.push(accountLevel1.account);
            if (accountLevel1.accounts) {
                for (const account of accountLevel1.accounts) {
                    if (account.type === `Division` || account.type === `District`) {
                        allAccounts.push(account.account);
                    }
                    if (account.accounts) {
                        for (const account2 of account.accounts) {
                            if (account2.type === `Division` || account2.type === `District`) {
                                allAccounts.push(account2.account);
                            }
                        }
                    }
                }
            }
        }
        setAccountKeys(allAccounts);
    };

    /**
     * Resets the state of the modal before closing
     */
    const onClose = () => {
        // Clear modal state
        accountsRef.current = [];
        setErrorMessage(``);

        // Clear any pending workflow actions
        userStateService.clearPendingAction();

        // Invoke provided onHide()
        onHide();
    };

    /**
     * Handles logic to "drill" or "select" account
     * @param accountNum - Account number selection
     * @param contactID - Contact ID selection
     * @param returnUrlParam - URL to return to after selection
     */
    const selectAccount = (accountNum: string, contactID: string, returnUrlParam?: string) => {
        returnUrlParam = returnUrlParam || returnUrl;
        const currentDivisionOrDistrictUser = User.isDivisionOrDistrictLevel(user.highestLevelAccount);
        const switchingToDivisionOrDistrict = User.isDivisionOrDistrictLevel(accountNum);
        if (selectAccountMode || (currentDivisionOrDistrictUser && switchingToDivisionOrDistrict)) {
            usersService.selectAccount(accountNum, contactID, returnUrlParam);
        } else {
            usersService
                .drillAccount(accountNum)
                .then(() => {
                    location.assign(returnUrlParam);
                })
                .catch((drillAccountErr: ImpError) => {
                    setErrorMessage(drillAccountErr.message);
                });
        }
    };

    /**
     * Template
     */
    return (
        <ContentModal
            onClose={onClose}
            show={show}
            title={'Account Finder'}
        >
            <div>
                {errorMessage && (
                    <Alerts
                        message={errorMessage}
                        variant="danger"
                    />
                )}
                {accounts.length === 0 && !errorMessage && <EmptyState message={<Spinner />} />}
                {accounts.length > 0 && (
                    <>
                        <FilterInput
                            className="tw-mb-4"
                            onInput={(e) => {
                                setAccountKeys([]);
                                setFilter(e);
                            }}
                            placeholder="Search by name, city, site ID..."
                        />
                        <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-mb-4">
                            <div className="tw-pb-3 md:tw-pb-0">
                                <span className="tw-font-bold">
                                    {user.accountDisplayName === 'No Account Selected'
                                        ? 'Select a login to proceed'
                                        : `Currently logged in as: ${user.accountDisplayName}`}
                                </span>
                            </div>
                            {!filter && accounts[0]?.account && User.getAccountType(accounts[0].account) !== `Account` && (
                                <Button
                                    className="tw-relative tw-w-full md:tw-w-[118px] tw-flex"
                                    onClick={() => {
                                        setCollapsed(!collapsed);
                                        if (collapsed) {
                                            setAccountKeys([]);
                                        } else {
                                            collapseDivs();
                                        }
                                    }}
                                    size="sm"
                                    variant="outline-secondary"
                                >
                                    {collapsed ? `Expand` : `Collapse`} All
                                </Button>
                            )}
                        </div>
                        <div className="-tw-mx-7 md:tw-mx-0">
                            {accounts.filter(filterVisible()).map((level1Account) => {
                                const AccountFinderView = getAccountFinderView(level1Account.type);
                                return (
                                    <AccountFinderView
                                        account={level1Account}
                                        accountKeys={accountKeys}
                                        accountOnlyMode={accountOnlyMode}
                                        key={level1Account.account}
                                        selectAccount={selectAccount}
                                        setAccountKeys={setAccountKeys}
                                        user={user}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </ContentModal>
    );
};
