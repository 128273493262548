import {AccountRegContactType} from '../../shared/users/user.types';

type GaEvent =
    | `ga4_account_creation`
    | `ga4_apply_promo`
    | `ga4_category_click`
    | `ga4_facet_search`
    | `ga4_guided_navigation`
    | `ga4_login`
    | `ga4_navigation_facet`
    | `ga4_search_overlay_click`
    | `ga4_search_overlay_close`
    | `ga4_search_overlay_null_action`
    | `ga4_search_overlay_null_result`
    | `ga4_search_overlay_open`
    | `ga4_search_results_null_action`
    | `ga4_site_search`
    | `ga4_view_redirected_search_results`
    | `ga4_view_search_results`;

export type LoginMethod = `create_account` | `login_modal` | `login_page`;

export interface GaEventOptions {
    account_type?: AccountRegContactType;
    click_action?: `add_facet` | `navigation` | `remove_facet`;
    click_category_1?: `category_tile` | `facet_chip` | `filter_menu` | `guided_navigation_swiper`;
    click_category_2?: string;
    click_path?: string;
    click_type?:
        | `category_click`
        | `click_to_call`
        | `guided_navigation`
        | `list_facet`
        | `modal_close`
        | `modal_open`
        | `remove_facet`
        | `search_overlay_categories`
        | `search_overlay_exact_match`
        | `search_overlay_similar_products`
        | `send_email`
        | `start_chat`
        | `visual_attributes_facet`;
    coupon?: string;
    error_message?: string;
    had_search_results?: boolean;
    login_method?: LoginMethod;
    request_status?: `Request Pending` | `Request Success` | `Submission Error`;
    search_input?: string;
    search_method?: `recent_search_term` | `standard_search`;
    search_term?: string;
    selection_id?: string;
    selection_name?: string;
    user_id?: string;
}

/**
 * Pushes provided gaEvent to the dataLayer
 * @param gaEvent
 * @param gaEventOptions
 */
export const pushGaEvent = (gaEvent: GaEvent, gaEventOptions: GaEventOptions) => {
    // Format search_input
    if (gaEventOptions.search_input) {
        gaEventOptions.search_input = formatAnalyticsString(gaEventOptions.search_input, true);
    }

    // Format search_term
    if (gaEventOptions.search_term) {
        gaEventOptions.search_term = formatAnalyticsString(gaEventOptions.search_term, true);
    }

    // Format selection_name
    if (gaEventOptions.selection_name) {
        gaEventOptions.selection_name = formatAnalyticsString(gaEventOptions.selection_name, false);
    }

    // Push to dataLayer
    dataLayer.push({
        event: gaEvent,
        ...gaEventOptions,
    });
};

/**
 * Normalizes the provided text to support easier reporting
 * @param text - Text to format
 * @param transform - If additional normalization should be applied
 */
export const formatAnalyticsString = (text: string, transform: boolean) => {
    // Convert non-string values
    if (typeof text !== `string`) {
        text = `${text}`;
    }

    // Trim string
    text = text.trim();

    // Transform text, if specified
    if (transform) {
        return text.toLowerCase().replace(/["']/g, ``);
    }
    return text;
};
