import * as React from 'react';
import {useEffect, useState} from 'react';

import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {SearchOverlayCategorySuggestion} from './SearchOverlayCategorySuggestion';
import {SearchOverlayProductSuggestion} from './SearchOverlayProductSuggestion';
import {SearchSuggestions} from '../../search/search.types';
import {User} from '../../users/user.class';

interface SearchOverlayResultsProps {
    addToOrder: (itemNum: string, addToOrderQty: number, gaItemListName: GaItemListName) => void;
    closeOverlay: () => void;
    recordSearchTerm: () => void;
    searchString: string;
    searchSuggestions: SearchSuggestions;
    user: User;
}

export const SearchOverlayResults = ({
    addToOrder,
    closeOverlay,
    recordSearchTerm,
    searchString,
    searchSuggestions,
    user,
}: SearchOverlayResultsProps) => {
    const [nullResult, setNullResult] = useState(false);

    useEffect(() => {
        if (
            searchSuggestions &&
            (!searchSuggestions.categories || searchSuggestions.categories.length === 0) &&
            (!searchSuggestions.partNumbers || searchSuggestions.partNumbers.length === 0) &&
            (!searchSuggestions.products || searchSuggestions.partNumbers.length === 0) &&
            !searchSuggestions.mroMatch
        ) {
            setNullResult(true);
        } else {
            setNullResult(false);
        }
    }, [searchSuggestions]);

    if (!searchSuggestions) {
        return null;
    }

    /**
     * Template
     */
    return (
        <div className="tw-grid tw-grid-cols-1">
            <div
                className={`${nullResult ? 'tw-w-full tw-relative tw-p-6 lg:tw-p-2' : 'tw-w-full tw-relative'} tw-overflow-x-auto`}
                id="categoryLayout"
            >
                {searchSuggestions.partNumbers && searchSuggestions.partNumbers.length > 0 && (
                    <>
                        <div className="tw-border-t lg:tw-border-t-0 tw-mb-0">
                            <h5 className="tw-text-black tw-p-4 tw-text-sm tw-font-bold tw-leading-[1.3] tw-uppercase tw-mb-0 lg:tw-opacity-70 lg:tw-font-normal lg:tw-leading-[1.2] lg:tw-text-xl lg:tw-normal-case">
                                Exact Match
                            </h5>
                        </div>
                        <div className="tw-mx-0 lg:tw-grid lg:tw-grid-cols-4 xl:tw-grid-cols-5 lg:tw-justify-start">
                            {searchSuggestions.partNumbers.map((productSuggestion, index) => (
                                <SearchOverlayProductSuggestion
                                    addToOrder={addToOrder}
                                    closeOverlay={closeOverlay}
                                    currentProductsQueryID={searchSuggestions.queryIDs.products}
                                    index={index}
                                    key={`partNumber${index}`}
                                    productSuggestion={productSuggestion}
                                    recordSearchTerm={recordSearchTerm}
                                    searchString={searchString}
                                    suggestionType="partNumbers"
                                    user={user}
                                />
                            ))}
                        </div>
                    </>
                )}
                {searchSuggestions.categories && searchSuggestions.categories.length > 0 && (
                    <>
                        <div className="tw-border-t lg:tw-border-t-0 tw-mb-0">
                            <h5 className="tw-text-black tw-p-4 tw-text-sm tw-font-bold tw-leading-[1.3] tw-uppercase tw-mb-0 lg:tw-opacity-70 lg:tw-font-normal lg:tw-leading-[1.2] lg:tw-text-xl lg:tw-normal-case">
                                Categories
                            </h5>
                        </div>
                        <div className="lg:!tw-grid lg:!tw-grid-cols-3">
                            {searchSuggestions.categories.map((categorySuggestion, index) => (
                                <SearchOverlayCategorySuggestion
                                    categorySuggestion={categorySuggestion}
                                    searchString={searchString}
                                    currentCategoryQueryID={searchSuggestions.queryIDs.categories}
                                    index={index}
                                    key={`partNumber${index}`}
                                    recordSearchTerm={recordSearchTerm}
                                />
                            ))}
                        </div>
                    </>
                )}
                {searchSuggestions.products && searchSuggestions.products.length > 0 && (
                    <>
                        <div className="tw-border-t lg:tw-border-t-0 tw-mb-0">
                            <h5 className="tw-text-black tw-p-4 tw-text-sm tw-font-bold tw-leading-[1.3] tw-uppercase tw-mb-0 lg:tw-opacity-70 lg:tw-font-normal lg:tw-leading-[1.2] lg:tw-text-xl lg:tw-normal-case">
                                Products
                            </h5>
                        </div>
                        <div className="tw-mx-0 lg:tw-grid lg:tw-grid-cols-4 xl:tw-grid-cols-5 lg:tw-justify-start">
                            {searchSuggestions.products.map((productSuggestion, index) => (
                                <SearchOverlayProductSuggestion
                                    addToOrder={addToOrder}
                                    closeOverlay={closeOverlay}
                                    currentProductsQueryID={searchSuggestions.queryIDs.products}
                                    index={index}
                                    key={`product${index}`}
                                    productSuggestion={productSuggestion}
                                    recordSearchTerm={recordSearchTerm}
                                    searchString={searchString}
                                    suggestionType="products"
                                    user={user}
                                />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
