import * as React from 'react';
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ImpressionTracker from '../../analytics/impression-tracker/ImpressionTracker';
import {formatCurrency} from '../../formatCurrency';
import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {ItemLine} from './AddToOrderModal';
import {Item} from '../../items/item.class';
import {Order} from '../order.class';
import {replaceHTMLQuotes} from '../../../client/components/helpers/stringHelper';

interface AddToOrderModalItemCardProps {
    gaItemListName: GaItemListName;
    itemLine: ItemLine;
    order: Order;
}

export const AddToOrderModalItemCard = ({gaItemListName, itemLine, order}: AddToOrderModalItemCardProps) => {
    return (
        <div className="tw-p-4">
            <div className="tw-text-sm tw-font-normal tw-leading-[1.3]">
                <ImpressionTracker data={{dimension16: order.ordrNbr, index: 1, item_id: itemLine.item, item_list_name: gaItemListName}}>
                    <a href={`/item/${itemLine.item}`}>#{Item.prettyItem(itemLine.item)}</a>
                </ImpressionTracker>
            </div>
            <div className="tw-mb-4">{itemLine.name}</div>
            <div className="tw-grid tw-grid-cols-2">
                <div className="tw-w-full tw-relative">
                    <img
                        alt={replaceHTMLQuotes(itemLine.name)}
                        height={75}
                        src={`/ProductImageThumbs75${itemLine.imagePath || '/noimage.png'}`}
                        width={75}
                    />
                </div>
                <div className="tw-w-full tw-relative tw-text-right">
                    <div>Pkg Qty: {itemLine.pkgQty}</div>
                    <div className="tw-mb-2">Order Qty: {itemLine.itemLineQty}</div>
                    {itemLine.itemLineAmount !== undefined && (
                        <h5 className="tw-font-bold">{formatCurrency(itemLine.itemLineAmount, order.currency)}</h5>
                    )}
                </div>
            </div>
            {itemLine.msg && <div>{itemLine.msg}</div>}
            {itemLine.hasPandemicFlag && (
                <div className="tw-text-red-500 tw-align-text-top">
                    <FontAwesomeIcon icon={faExclamationCircle} /> Limited Supply Item
                </div>
            )}
        </div>
    );
};
