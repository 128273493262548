import {FetchService} from '../fetch/fetch.service';
import {ImpError} from '../imp-error/imp-error.class';
import {
    CartDetailEvent,
    GetRecoItemsParams,
    MonetateItem,
    ProductId,
    RecosScheme,
    SendRecoEventsParams,
} from '../../shared/monetate/monetate.types';

export class MonetateService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Records monetate addCart event
     * @param pageType
     * @param monetateItems
     */
    public addCart(pageType: string, monetateItems: MonetateItem[]) {
        monetateQ.push([`setPageType`, pageType]);
        monetateQ.push([`addCartRows`, monetateItems]);
        monetateQ.push([`trackData`]);
    }

    /**
     * TBD
     * @param cartDetailEvent
     */
    public cartDetail(cartDetailEvent: CartDetailEvent) {
        monetateQ.push([`setPageType`, cartDetailEvent.pageType]);
        if (cartDetailEvent.items) {
            monetateQ.push([`addCartRows`, cartDetailEvent.items]);
            monetateQ.push([`trackData`]);
        }
    }

    /**
     * Gets product recommendations from Monetate
     * @param getRecoItemsParams - Params to be relayed to Reco
     */
    public getRecoItems(getRecoItemsParams: GetRecoItemsParams): Promise<RecosScheme[]> {
        return new Promise((resolve, reject) => {
            // Build queryString
            let queryString = `scheme=${getRecoItemsParams.scheme}`;
            if (getRecoItemsParams.itemid) {
                queryString = queryString + `&itemid=${getRecoItemsParams.itemid}`;
            }
            if (getRecoItemsParams.stepIdPath) {
                queryString = queryString + `&stepIdPath=${getRecoItemsParams.stepIdPath}`;
            }
            if (getRecoItemsParams.webcat) {
                queryString = queryString + `&webcat=${getRecoItemsParams.webcat}`;
            }

            // getRecoItems
            this._fetchService
                .get<RecosScheme[]>(`/api/monetate/getRecoItems?${queryString}`)
                .then((getRecoItemsRes) => {
                    resolve(getRecoItemsRes);
                })
                .catch((getRecoItemsErr: ImpError) => {
                    reject(getRecoItemsErr);
                });
        });
    }

    /**
     * Sends items to Monetate users have viewed and clicked on
     * @param sendRecoEventsParams
     */
    public sendRecoEvents(sendRecoEventsParams: SendRecoEventsParams) {
        // Build queryString
        let queryString = ``;
        if (Array.isArray(sendRecoEventsParams.recToken)) {
            queryString += `&recToken=${sendRecoEventsParams.recToken.join('|')}`;
        } else if (sendRecoEventsParams.recToken) {
            queryString += `&recToken=${sendRecoEventsParams.recToken}`;
        }
        if (sendRecoEventsParams.eventType) {
            queryString += `&eventType=${sendRecoEventsParams.eventType}`;
        }

        // Send impression to Monetate
        this._fetchService.get(`/api/monetate/sendRecoEvents?${queryString}`).catch(() => {
            // Error silently
        });
    }

    /**
     * Sends provided productIds from search result to monetate
     * @param productIds
     * @param pageType
     */
    public searchResults(productIds: ProductId[], pageType: string) {
        if (productIds.length) {
            monetateQ.push([`addProducts`, productIds]);
            monetateQ.push([`setPageType`, pageType]);
            monetateQ.push([`trackData`]);
        }
    }

    /**
     * Records an item detail view event
     * @param itemNum
     */
    public itemDetail(itemNum: string) {
        monetateQ.push([`setPageType`, `product`]);
        monetateQ.push([
            `addProductDetails`,
            [
                {
                    productId: itemNum,
                },
            ],
        ]);
        monetateQ.push([`trackData`]);
    }

    /**
     * TBD
     * @param monetateItems
     */
    public purchase(monetateItems: MonetateItem[]) {
        monetateQ.push([`setPageType`, `purchase`]);
        monetateQ.push([`addPurchaseRows`, monetateItems]);
        monetateQ.push([`trackData`]);
    }

    /**
     * TBD
     * @param pageType
     */
    public setPageType(pageType: string) {
        monetateQ.push([`setPageType`, pageType]);
        monetateQ.push([`trackData`]);
    }
}
